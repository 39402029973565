<template>
  <button
    :data-name="name"
    class="SubmitButton"
    type="submit">
    <div class="label">
      {{ label }}
    </div>

    <transition name="fade">
      <div
        v-if="working"
        class="spinner">
        <svgicon
          class="spinnerIcon"
          icon="loadwheel" />
      </div>
    </transition>
  </button>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },
        name: {
            type: String
        },
        working: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
$disabledColor: #cacaca;
$transitionSpeed: 0.5s;

.SubmitButton {
    position: relative;
    background-color: $greenButtonBackground;
    border-radius: 2em;
    font: inherit;
    color: white;
    text-align: center;
    padding: 1em 2em;
    outline: none;
    border: none;

    cursor: pointer;

    display: inline-flex;
    justify-content: center;

    &[disabled] {
        background-color: $disabledColor;
        cursor: default;
    }

    .spinnerIcon {
        $size: 1.3em;
        display: block;
        width: $size;
        height: $size;
        fill: rgba(white, 0.9);
        margin-left: 1em;
        animation: rotate-360 2s linear infinite;
    }
}

.spinner {
    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity $transitionSpeed, max-width $transitionSpeed;
        max-width: 3em;
    }
    &.fade-enter,
    &.fade-leave-to {
        opacity: 0;
        max-width: 0;
    }

    @keyframes rotate-360 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
