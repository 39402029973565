<template>
  <div
    class="FormGroup"
    :class="{ invalid: invalid }">
    <label
      :for="uid"
      class="label">
      {{ dynamicLabel }}
    </label>
    <slot />
    <PlainWrapper>
      <input
        :id="uid"
        ref="input"
        class="input"
        :name="name"
        :data-name="name"
        :value="value"
        :type="type"
        :autocomplete="autocomplete"
        @input="onValueChanged">
    </PlainWrapper>
  </div>
</template>

<script>
import PlainWrapper from '@/components/PlainWrapper';
import { v4 as uuidv4 } from 'uuid';

export default {
    components: { PlainWrapper },
    props: {
        label: {
            type: String
        },
        v: {
            type: Object
        },
        value: {
            type: String
        },
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        debounce: {
            type: Number,
            default: 0
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        id: {
            type: String,
            default: ''
        }
    },
    computed: {
        uid() {
            return this.id || `${this.name}_${uuidv4()}`;
        },
        invalid() {
            if (this.v && this.v.$dirty) {
                return this.v.$invalid;
            } else {
                return false;
            }
        },
        dynamicLabel() {
            return this.invalid ? this.errorMessage : this.label;
        },
        errorMessage() {
            if (!this.v.$dirty) {
                return this.label + '-- not dirty';
                // return null
            }
            if (this.v.$pending) {
                return this.label + ' ...';
            }

            if (this.v.required === false) {
                return this.label + ' ' + this.$t('account.validationMessages.required');
            } else if (this.v.minLength === false) {
                return (
                    this.label +
                    ' ' +
                    this.$tc('account.validationMessages.minLength', this.v.$params.minLength.min, {
                        min: this.v.$params.minLength.min
                    })
                );
            } else if (this.v.email === false) {
                return this.label + ' ' + this.$t('account.validationMessages.invalid');
            } else if (this.v.sameAsPassword === false) {
                return this.label + ' - ' + this.$t('account.validationMessages.mustBeIdentical');
            } else if (this.v.voucher === false) {
                return this.label + ' - ' + this.$t('account.validationMessages.couponError');
            }
            return '';
        }
    },
    methods: {
        onValueChanged(event) {
            this.$emit('input', event.target.value);
            if (this.v) {
                this.v.$touch();
            }
        },

        focus() {
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
$grayLineColor: #ddd;
$errorColor: #b32e28; //red;

.FormGroup {
    display: block;

    &.invalid {
        border-width: 5px;
        .label,
        .error {
            color: $errorColor;
        }

        .input {
            border: 1px solid darken($errorColor, 20%);
        }
    }
}

.label,
.error {
    display: inline-block;
    cursor: default;
    color: gray;
    margin-bottom: 0.3em;
    font-size: 90%;
}

.input {
    outline: none;
    padding: 0.5em 1em;
    font: inherit;
    width: 100%;
    border: 1px solid $grayLineColor;

    color: #555;

    &:focus {
        border-color: blue;
    }

    &[type='password'] {
        letter-spacing: 2px;
    }
}
</style>
